/*
 * @Description:
 * @Author: Pengfei.Wang
 * @Date: 2022-05-07 17:17:57
 * @LastEditors: Pengfei.Wang
 * @LastEditTime: 2022-10-18 14:49:09
 * @version: 1.0
 * Copyright(C)中国软件与技术服务股份有限公司-版权所有
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

import baseRouter from './base-router'
import reportsRouter from './reports-router'
import noticeRouter from './notice-router'
import userRouter from './user-router'
import aboutRouter from './about-router'
import logRouter from './log-router'
import scheduleRouter from './schedule-router'
import portalRouter from './portal-router'
import homeRouter from './home-router'
import workflowRouter from './workflow-router'
import msgRouter from './msg-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BACKEND_CONTEXT_PATH,
  scrollBehavior: () => ({ y: 0 }),
  routes: [...baseRouter, ...reportsRouter, ...noticeRouter, ...userRouter, ...aboutRouter, ...logRouter, ...scheduleRouter, ...portalRouter, ...homeRouter, ...workflowRouter, ...msgRouter]
})

export default router
