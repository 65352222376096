// 定义一个混入对象
import { mapGetters } from 'vuex'
import { dictionarySelectDictionaryListByKey } from '@/api/dictionary'
import { loginGetCurrentUserInfo } from '@/api/user'
import { query } from '@/utils'
export default {
  computed: {
    ...mapGetters({
      appPlatform: 'app/platform',
      appAppEnv: 'app/appEnv',
      userToken: 'user/token',
      userUserInfo: 'user/userInfo',
      appBackendContextPath: 'app/backendContextPath'
    })
  },
  data() {
    return {
      isInit: false
    }
  },
  created() {},
  mounted() {
    // if (/Mobile|Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    //   location.href = `/${this.appBackendContextPath}/mobile`
    // }
    this.init()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handlerResize)
  },
  watch: {
    $route: function () {
      this.setTitle()
      this.validateParams()
    }
  },
  methods: {
    isMobile() {
      if (document.documentElement.clientWidth < 660) {
        return true
      } else {
        return false
      }
    },
    // 初始化
    async init() {
      try {
        // 初始化左侧菜单状态
        this.setAsider()

        // 设置标题
        this.setTitle()

        // 校验加密参数
        this.validateParams()

        // 获取系统配置
        await this.dictionarySelectDictionaryListByKeyService()

        // 获取用户信息
        await this.loginGetCurrentUserInfoService()
        this.isInit = true
      } catch (error) {}
    },
    // 初始化左侧菜单状态
    setAsider() {
      this.handlerResize()
      window.addEventListener('resize', this.handlerResize)
    },
    handlerResize() {
      if (document.documentElement.clientWidth > 1180) {
        this.$store.dispatch('app/setSidebar', { collapse: false })
      } else {
        this.$store.dispatch('app/setSidebar', { collapse: true })
      }
    },
    // 校验加密参数
    validateParams() {
      if (!['/', '/portal/login', '/reports/mine/edit1', '/reports/help/edit1'].includes(this.$route.path)) {
        if (JSON.stringify(this.$route.query) !== '{}') {
          const params = JSON.parse(JSON.stringify(this.$route.query))
          delete params.ENCODE
          if (query(params).ENCODE !== this.$route.query.ENCODE) {
            this.isInit = true
            this.$router.push('/error/401')
          }
        }
      }
    },

    dictionarySelectDictionaryListByKeyService() {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await Promise.all([
            dictionarySelectDictionaryListByKey({ key: 'systemName' }),
            dictionarySelectDictionaryListByKey({ key: 'companyName' }),
            dictionarySelectDictionaryListByKey({ key: 'companyShortName' }),
            dictionarySelectDictionaryListByKey({ key: 'copyRight' }),
            dictionarySelectDictionaryListByKey({ key: 'approveUserId' }),
            dictionarySelectDictionaryListByKey({ key: 'systemSecretValue' }),
            dictionarySelectDictionaryListByKey({ key: 'expireTime' }),
            dictionarySelectDictionaryListByKey({ key: 'loginNum' }),
            dictionarySelectDictionaryListByKey({
              key: 'systemCompanySortName'
            }),
            dictionarySelectDictionaryListByKey({ key: 'systemNameEn' })
          ])
          let result = {}
          for (let i = 0; i < data.length; i++) {
            if (data[i].code === 200) {
              if (data[i] && data[i].data && data[i].data[0]) {
                result[data[i].data[0].key] = data[i].data[0]
              }
            } else {
              result = null
              break
            }
          }
          if (!result) {
            this.$message.error('获取系统配置失败')
            reject(new Error('获取系统配置失败'))
          } else {
            this.$store.dispatch('app/setSysConfig', result)
            resolve()
          }
        } catch (error) {
          this.$message.error('获取系统配置失败')
          reject(error)
        }
      })
    },
    // 设置title
    setTitle() {
      if (this.$route.meta.title) {
        if (this.appPlatform === 'gx') {
          document.title = '企票通信用测算'
        } else {
          document.title = this.$route.meta.title
        }
      }
    },
    // 获取用户信息
    loginGetCurrentUserInfoService() {
      return new Promise(async (resolve, reject) => {
        try {
          if ((location.href.includes('platform=aqhb') && !location.href.includes('nolink')) || location.href.includes('aqhbcode')) {
            return resolve()
          }
          if (location.href.includes('platform=zzwl') && !location.href.includes('nolink')) {
            return resolve()
          }
          if (location.href.includes('platform=gxjf') && !location.href.includes('nolink')) {
            return resolve()
          }
          if (location.href.includes('platform=gxFlow') && !location.href.includes('nolink')) {
            return resolve()
          }
          // 如果有token
          if (this.userToken) {
            const data = await loginGetCurrentUserInfo()
            if (data.code === 200) {
              this.$store.dispatch('user/setUserInfo', data.data)
              // if (this.$route.path === '/' && this.isMobile()) {
              //   location.href = `/${this.appBackendContextPath}/mobile/list`
              //   return
              // }
              if (this.$route.path === '/') {
                if (this.userUserInfo.umsUser.roleType === 0) {
                  if (this.appPlatform === 'gx' || this.appPlatform === 'zzwl') {
                    this.isInit = true

                    if (this.$route.query.redierct) {
                      const routeList = this.$route.query.redierct.split('?')
                      const query = {}
                      if (routeList[1]) {
                        routeList[1].split('&').forEach((element) => {
                          query[element.split('=')[0]] = element.split('=')[1]
                        })
                        this.$router.replace({
                          path: routeList[0],
                          query: this.$query(query)
                        })
                      } else {
                        this.$router.replace({
                          path: routeList[0]
                        })
                      }
                    } else {
                      this.$router.push('/reports/riskControlTemplate/list')
                    }
                  } else {
                    // 普通用户 跳转到填报管理
                    this.$router.push('/home/manage')
                  }
                  // this.$router.push('/reports/manage/list')
                } else if (this.userUserInfo.umsUser.roleType === 1) {
                  if (this.appPlatform === 'gx' || this.appPlatform === 'zzwl') {
                    this.isInit = true

                    if (this.$route.query.redierct) {
                      const routeList = this.$route.query.redierct.split('?')
                      const query = {}
                      if (routeList[1]) {
                        routeList[1].split('&').forEach((element) => {
                          query[element.split('=')[0]] = element.split('=')[1]
                        })
                        this.$router.replace({
                          path: routeList[0],
                          query: this.$query(query)
                        })
                      } else {
                        this.$router.replace({
                          path: routeList[0]
                        })
                      }
                    } else {
                      this.$router.push('/reports/riskControlModel/list')
                    }
                  } else {
                    // 普通用户 跳转到填报管理
                    this.$router.push('/home/manage')
                  }
                  // this.$router.push('/reports/manage/list')
                } else if (this.userUserInfo.umsUser.roleType === 2) {
                  if (this.appPlatform === 'gx' || this.appPlatform === 'zzwl') {
                    this.isInit = true
                    if (this.$route.query.redierct) {
                      const routeList = this.$route.query.redierct.split('?')
                      const query = {}
                      if (routeList[1]) {
                        routeList[1].split('&').forEach((element) => {
                          query[element.split('=')[0]] = element.split('=')[1]
                        })
                        this.$router.replace({
                          path: routeList[0],
                          query: this.$query(query)
                        })
                      } else {
                        this.$router.replace({
                          path: routeList[0]
                        })
                      }
                    } else {
                      this.$router.push('/reports/riskControlModel/list')
                    }
                  } else {
                    // 普通用户 跳转到我的填报列表
                    // this.$router.push('/home/manage')
                    this.$router.push('/reports/mine/list')
                  }
                } else if (this.userUserInfo.umsUser.roleType === 3) {
                  // 普通用户 跳转到帮忙填报列表
                  this.$router.push('/reports/help/list')
                } else if (this.userUserInfo.umsUser.roleType === 99) {
                  // 普通用户 跳转到用户管理列表
                  this.$router.push('/user/list')
                }
              } else if (this.$route.path === '/portal/login') {
                if (this.userUserInfo.umsUser.roleType === 99) {
                  // 普通用户 跳转到用户管理列表
                  this.$router.push('/user/list')
                } else {
                  this.$router.push('/portal/home')
                }
              } else {
                resolve()
              }
            } else {
              // this.$message.error(data.message)
              this.$store.dispatch('user/fedLogOut')
              if (this.$route.path !== '/') {
                this.$router.push('/')
              }
              reject(data.message)
            }
          } else {
            // 如果没有token 如果是在白名单直接跳转，如果没在白名单返回登录页面
            if (['/portal/login', '/'].includes(this.$route.path)) {
              resolve()
            } else {
              this.$router.push('/')
            }
          }
        } catch (error) {
          this.$store.dispatch('user/fedLogOut')
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
          reject(new Error('获取用户信息失败'))
        }
      })
    }
  }
}
